import { useTranslation } from "react-i18next"
import micIcon from '../../../../assets/images/mic.svg'
import plusIcon from '../../../../assets/images/pluse-circle.svg'
import audioSendIcon from '../../../../assets/images/audio-send.svg'
import { useFormContext, useWatch } from "react-hook-form"
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react"
import { ResultReason, ProfanityOption, SpeechConfig, AudioConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk'
import { EAPIStatus } from "../../../../shared/api/models"
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk'
import sendMessageImg from '../../../../assets/images/send-button.svg';
import { apiServiceCSharp } from "../../../../shared/api/axios"
import { IChatForm, IChatMessage, IHumanStudentTurnSendInputPayload } from "../../chat.interfaces"
import { useAppDispatch, useAppSelector } from "../../../../app/store"
import { useRenewableCookie } from "../../../../shared/hooks/useRenewableCookie"
import { useLocalStorage } from "../../../../shared/utils/useLocalStorage"
import { API_ROUTES, chatInputId, chatSessionIdLocalStorageKey, animationDurationTaskFormInMs, stageParam  } from "../../../../app/constants"
import { ISpeechTokenResponse } from "./speech.interfaces"
import { speechCookieParseToTokenPayload } from "./speech.utils"
import { HumanStudentTurnSendInput, setQueueMessageData } from "../../chat.store"
import { ApplicationInsightsApi } from "../../../../application-insights"
import { ConfirmModal } from "../../../../shared/components/confirm-modal/ConfirmModal"
import { useChatMessageQueue } from "../../../../shared/hooks/useChatMessageQueue"
import { useSearchParams } from "react-router-dom"
import { isMobileDevice } from "../../../../shared/utils/isMobileDevice"
import { shouldDisplayTime } from "../Chat.utils"
import { setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom, setShouldStartDestroyAnimationTaskForm } from "../../resizable-container/stage-container/stage-tasks/stageTasks.store"
import { onResizeTextareaHeightByTheContext } from "../../../../shared/utils/utils"
import createAppOverlayPopover from "../../../../shared/components/app-overlay-popover/createAppOverlayPopover"
import removeAppOverlayPopover from "../../../../shared/components/app-overlay-popover/removeAppOverlayPopover"
import { EAppStageContentType } from "../../resizable-container/stage-container/stageContainer.interfaces"
import './ChatFormUserInput.scss';
import { ETaskFormType } from "../../resizable-container/stage-container/stage-tasks/stageTasks.interface"
import AppButton from "../../../../shared/components/app-button/AppButton"

interface IProps {
  appendNewMessage: (messages: IChatMessage[], shouldResetOrFocusInputField?: 'reset' | 'focus') => void;
}

export const ChatFormUserInput = ({ appendNewMessage }: IProps) => {
  const [cookieValue] = useRenewableCookie(
    {
      cookieName: "speech-token",
      expirationTimer: 540, // 10 minutes
      renewTime: 540000, // renew after 9 minutes just in case
      callback: async () => {
        const response = await apiServiceCSharp.get<ISpeechTokenResponse>(API_ROUTES.SPEECH.GET_SPEECH_SERVICE_TOKEN);
        const { token, region } = response.data;
        return region + ':' + token;
      }
    }
  );
  const { resetField, setValue, getValues, register, formState, control, setFocus, watch } = useFormContext<IChatForm>();
  const { sessionId } = useAppSelector(store => store.chatReducer);
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const speechTokenDataRef = useRef<ISpeechTokenResponse | null>(null);
  const { t } = useTranslation();
  const recorderRef = useRef<SpeechRecognizer | null>(null);
  const [isRecorderLoading, setIsRecorderLoading] = useState(false);
  const recognizedTextRef = useRef<string>('');
  const dispatch = useAppDispatch();
  const [shouldDisplayErrorModal, setShouldDisplayErrorModal] = useState(false);
  const [currentlyProcessingChatMessageType, nextMessageToProcess] = useChatMessageQueue();
  const [searchParams, setSearchParams] = useSearchParams();
  const { shouldOpenAddEditTaskFrom, selectedMainTaskForEditing } = useAppSelector(store => store.StageTasksReducer);
  const { clickNavbarConfirmModalText } = useAppSelector(store => store.sharedStoreReducer);
  const openTaskFormAfterAnimationTimerRef = useRef<NodeJS.Timeout | null>(null);  const chatMessagesArr = useWatch({ control, name: 'messagesArr' });
  const isRecording = useWatch({ control, name: 'isRecording' });

  const userMessageWatch = watch('userMessage');

  const processChatMessageFromQueue = useCallback(() => {
    if (nextMessageToProcess) {
      dispatch(HumanStudentTurnSendInput(JSON.parse(nextMessageToProcess.botRequestJson)));
      if (nextMessageToProcess.localUserChatMessage) {
        appendNewMessage([JSON.parse(nextMessageToProcess.localUserChatMessage)]);
      }
    }
  }, [nextMessageToProcess, appendNewMessage, dispatch]);

  useEffect(() => {
    processChatMessageFromQueue();
  }, [processChatMessageFromQueue]);


  const setTokenDataRef = useCallback(() => {
    speechTokenDataRef.current = speechCookieParseToTokenPayload(cookieValue || "");
  }, [cookieValue]);

  const onSubmitChatForm = useCallback(() => {
    try {
      const formData = getValues();
      setValue('shouldDisplayRecordingErrorMsg', false);
      const sessionIdReq = sessionId?.data?.sessionId || sessionIdLocalStorage;
      const userMessage: IChatMessage = {
        party: 'User',
        msg: formData.userMessage.trim(),
        messageTime: Date.now(),
        creationTime: new Date().toISOString(),
        shouldDisplayTime: shouldDisplayTime(Date.now(), chatMessagesArr.length > 0 ? chatMessagesArr[chatMessagesArr.length - 1].messageTime : null),
        sessionId: sessionIdReq
      }
      const payload: IHumanStudentTurnSendInputPayload = {
        sessionId: sessionIdReq,
        studentInput: formData.userMessage,
      }
      resetField('userMessage');
      onResizeTextareaHeightByTheContext(document.getElementById(chatInputId));
      setFocus('userMessage');
      dispatch(setQueueMessageData({ type: 'manual', botRequestJson: JSON.stringify(payload), localUserChatMessage: JSON.stringify(userMessage) }))
    }
    catch (e) {
      ApplicationInsightsApi.trackException(e);
      console.error(e);
    }
  }, [getValues, setValue, sessionId?.data?.sessionId, sessionIdLocalStorage, chatMessagesArr, resetField, setFocus, dispatch]);

  const onSpeechSubmit = (text: string) => {
    setValue('userMessage', text);
    onSubmitChatForm();
  };

  const handleStartRecording = () => {
    try {
      if (cookieValue) {
        setIsRecorderLoading(true);
        const speechConfig: SpeechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(speechTokenDataRef.current?.token || "", speechTokenDataRef.current?.region || "");
        speechConfig.enableDictation();
        speechConfig.setProfanity(ProfanityOption.Removed);
        const audioConfig: AudioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        recorderRef.current = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
        recorderRef.current?.startContinuousRecognitionAsync(startRecordingCallBack, (e) => {
          setIsRecorderLoading(false);
          setValue('isRecording', false);
          if (e.toLowerCase().includes('permission')) {
            setShouldDisplayErrorModal(true);
            ApplicationInsightsApi.trackEvent('Speech to text client permission denied.', { message: e });
          }
        });
        subscribeToRecordingEvents();
      }
    } catch (error) {
      console.error(error);
      ApplicationInsightsApi.trackException(error);
      setIsRecorderLoading(false);
      setValue('isRecording', false);
    }
  };

  const startRecordingCallBack = () => {
    setIsRecorderLoading(false);
    recognizedTextRef.current = '';
    setValue('isRecording', true);
  }

  const subscribeToRecordingEvents = () => {
    if (recorderRef.current) {
      recorderRef.current.recognized = (_, event) => {
        if (event.result.reason === ResultReason.RecognizedSpeech) {
          recognizedTextRef.current += event.result.text + ' '; // each succesful speech to text recognition event is accumulated
        }
        if ([ResultReason.NoMatch].includes(event.result.reason)) {
          setValue('shouldDisplayRecordingErrorMsg', true);
        }
      }
    }
  }

  const handleStopRecording = (type: "send" | "cancel") => {
    if (recorderRef.current) {
      recorderRef.current.stopContinuousRecognitionAsync(() => {
        const userText = recognizedTextRef.current.trim()
        if (type === "send" && !!userText.trim().length) onSpeechSubmit(userText.trim());
        else if (type === "cancel") setValue('shouldDisplayRecordingErrorMsg', false);
        recorderRef?.current?.close();
        setValue('isRecording', false);
      })
    }
  }

  useEffect(() => {
    setTokenDataRef();
  }, [setTokenDataRef]);

  const handleTextareaKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    // if it is not a mobile device, pressing the 'enter' key will submit the form.
    if (!isMobileDevice() && event.key.toLowerCase() === 'enter' && !event.shiftKey && currentlyProcessingChatMessageType !== 'manual') {
      event.preventDefault();
      // Trigger the form submission
      submitFormIfMessageHasValidContent();
    }
  };

  const onClickSendMessageButton = () => {
    if (currentlyProcessingChatMessageType === 'manual') {
      setFocus('userMessage');
      return;
    }
    else submitFormIfMessageHasValidContent();
  }
  
  const submitFormIfMessageHasValidContent = () => {
    const hasValidContent = userMessageWatch.trim().length > 0;
    if (hasValidContent) onSubmitChatForm();
  }

  const openCreateTaskFormModal = (isFormAlreadyOpen: boolean) => {
    if (isFormAlreadyOpen) {
      dispatch(setShouldStartDestroyAnimationTaskForm(true));
      dispatch(setSelectedMainTaskForEditing(null));
      if (openTaskFormAfterAnimationTimerRef.current) clearTimeout(openTaskFormAfterAnimationTimerRef.current);
      openTaskFormAfterAnimationTimerRef.current = setTimeout(() => {
        dispatch(setShouldOpenAddEditTaskFrom(ETaskFormType.Task));
      }, animationDurationTaskFormInMs);
    }
    else {
      dispatch(setShouldOpenAddEditTaskFrom(ETaskFormType.Task));
      if (!searchParams.get(stageParam)) {
        setSearchParams(prev => {
          prev.delete(stageParam);
          prev.append(stageParam, EAppStageContentType.TASKS);
          return prev;
        });
      }
    };
  }

  const onClickPlusButton = () => {
    // if the form is open on edit mode or on create mode and the form is dirty
    if (shouldOpenAddEditTaskFrom && (!!selectedMainTaskForEditing || !!clickNavbarConfirmModalText)) {
      // if the edit form is open - reset the editItem and open the create form
      if (!!selectedMainTaskForEditing) openCreateTaskFormModal(true);
      // if the create form is opened already on create mode and the form is dirty, show confirmation modal
      else if (!!clickNavbarConfirmModalText) {
        createAppOverlayPopover(
          <ConfirmModal
            title={clickNavbarConfirmModalText || t("areYouSureNavbarDefaultConfirmText")}
            confirmBtnText={t("settingsResetProfileConfirmModalConfirmButtonText") + "."}
            onConfirm={() => { openCreateTaskFormModal(true); removeAppOverlayPopover(); }}
            onCancel={() => removeAppOverlayPopover()}
          />
        )
      }
    }
    // if the form is close
    else openCreateTaskFormModal(false);
  }

  return (
    <>
      <form data-testid="chat-form" id="chat-form" className="form">
        <section>
          <button
            type="button"
            className="open-create-task-form"
            onClick={onClickPlusButton}>
            <img src={plusIcon} alt="plus" />
          </button>
          <textarea
            {...register('userMessage', { required: true, minLength: 1 })}
            onInput={(e) => onResizeTextareaHeightByTheContext(e?.currentTarget)}
            onFocus={(e) => onResizeTextareaHeightByTheContext(e?.currentTarget)}
            onKeyDown={(e) => handleTextareaKeyPress(e)}
            disabled={getValues('isRecording') || [EAPIStatus.PENDING, EAPIStatus.REJECTED].includes(sessionId.status)}
            data-testid="student-input"
            id={chatInputId}
            className={`${isRecording ? 'recording' : ''}`}
            aria-label={t('chatStudentInputLabelText')}
            placeholder={isRecording ? t("chatFormRecordingPlaceholderText") : t("chatFormPlaceholderText")} autoComplete="off"
          />

          {isRecording &&
            <AppButton
              type="button"
              className="cancel-recording static-string"
              onClick={() => handleStopRecording("cancel")}
              id="chat-cancel-recording"
            >
              {t("cancelRecordingButtonText")}
            </AppButton>
          }

          {formState.isValid ?
            <AppButton
              form="chat-form"
              data-testid="chat-form-submit"
              id="chat-form-submit"
              className={`chat-form-submit ${currentlyProcessingChatMessageType === 'manual' ? 'chat-form-submit--disabled' : ''}`}
              type='button'
              onClick={onClickSendMessageButton}
            >
              <img
                src={sendMessageImg}
                alt={t("chatSendButtonAltText")}
                className='send-button'
              />
            </AppButton>
            :
            <AppButton
              onClick={() => { isRecording ? handleStopRecording("send") : handleStartRecording() }}
              data-testid="chat-form-audio"
              className="chat-form-submit"
              id={`${isRecording ? 'chat-send-recording' : 'chat-start-recording'}`}
              disabled={currentlyProcessingChatMessageType === 'manual'}>
              <img
                src={isRecording ? audioSendIcon : micIcon}
                alt={t("chatSendButtonAltText")}
                className={`mic-button${isRecording ? ' mic-button--active' : (isRecorderLoading || !cookieValue) ? ' mic-button--loading' : ""}`}
              />
            </AppButton>
          }
        </section>
      </form>
      {shouldDisplayErrorModal && <ConfirmModal title={t("chatRecordingPermissionModalText")}
        confirmBtnText={t("chatRecordingPermissionModalConfirmButtonText")}
        onConfirm={() => setShouldDisplayErrorModal(false)} />}
    </>
  )
}