import React, { FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { IMessageDataTask, ITaskFields, ITaskUpdateReqPayload } from '../../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { IOverlayStyle } from '../../../../../../../shared/components/app-overlay-popover/AppOverlayPopover';
import TasksListSingleItem from './TasksListSingleItem';
import SwipeableSingleTaskItemMobile from './swipeable-task-item-mobile/SwipeableSingleTaskItemMobile';
import { store, useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import { useLocalStorage } from '../../../../../../../shared/utils/useLocalStorage';
import { chatSessionIdLocalStorageKey } from '../../../../../../../app/constants';
import { getTasksListReqAction, updateTaskReqAction } from '../../../../../resizable-container/stage-container/stage-tasks/stageTasks.store';
import { batch } from 'react-redux';
import { setQueueMessageData } from '../../../../../chat.store';
import { createAutomatedTaskEvent } from '../TasksList.utils';
import { isMobileDevice } from '../../../../../../../shared/utils/isMobileDevice';

export enum ETaskViewType {
  IN_CHAT_TASK_LIST = 1,
  MY_TASKS,
  SCRATCHPAD,
  MY_PLAN_DUE,
  MY_PLAN_SCHEDULED,
  MY_WEEK,
  FULL_DAY_EVENT,
  CALENDAR_PLACEMENT,
}

interface ISingleTaskItemContainerProps {
  singleTask: IMessageDataTask;
  className?: string;
  shouldHideOnComplete?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  onUpdate?: (updatedTask: IMessageDataTask) => void;
  shouldDisplayActionButtons?: boolean
  actionOverlaysPositionStyle?: IOverlayStyle;
  shouldFetchUpdatedListAfterUpdateSingleTask?: boolean;
  shouldDisplayFirstTagColor?: boolean;
  showParentTaskDetails?: boolean;
  viewType?: ETaskViewType;
}

export interface ISingleTaskItemContainerChildrenProps extends ISingleTaskItemContainerProps {
  testid?: string,
  isPriority: boolean,
  setIsPriority: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateTask: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
}

const SingleTaskItemContainer: FunctionComponent<ISingleTaskItemContainerProps> = ( {shouldFetchUpdatedListAfterUpdateSingleTask=true, ...props }) => {
  const { sessionId } = useAppSelector(store => store.chatReducer);
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const [isPriority, setIsPriority] = useState(props.singleTask?.priority || false);
  const priorityChange = useMemo(()=>!!props.singleTask?.priority,[props.singleTask?.priority]);
  const dispatch = useAppDispatch();
  const isMobile = isMobileDevice();

  useEffect(() => {
    setIsPriority(priorityChange);
  },[priorityChange])

  // handles the hover / swipe quick task update actions (due, schedule, later)
  const onUpdateTask = (updatedField: ITaskFields, shouldSendMsgToTheBot: boolean = true): void => {
    let taskToUpdate = props.singleTask
    const isUpdatingDueDate = Object.keys(updatedField)[0] === 'dueDate';
    // if updating due date and the task is a multiple work time task, we need to update the parent task
    // in in recurrence, all updates are on the main task so no special handling is needed
    if (props.singleTask.isMultiWorkTimeTask && isUpdatingDueDate) {
      const parentTask = store.getState().StageTasksReducer.allTasks.find(t => t.id === props.singleTask.parentId);
      if (parentTask) taskToUpdate = parentTask;
    }
    if (!!taskToUpdate?.id) {
      const currentSessionId = sessionId?.data?.sessionId || sessionIdLocalStorage;

      const reqPayload: ITaskUpdateReqPayload = Object.assign({}, { sessionId: currentSessionId, id: taskToUpdate.id }, updatedField);

      dispatch(updateTaskReqAction(reqPayload)).unwrap()
        .then((updatedTask) => {
          batch(() => {
            const fieldsName = Object.keys(updatedField)[0] as keyof ITaskFields;
            if (shouldSendMsgToTheBot) dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(createAutomatedTaskEvent(currentSessionId, fieldsName, updatedField[fieldsName], taskToUpdate)) }));
            if (shouldFetchUpdatedListAfterUpdateSingleTask && fieldsName !== 'status') dispatch(getTasksListReqAction());
            if (!!props.onUpdate) props.onUpdate(updatedTask);
          });
        })

        // revert in failed
        .catch(() => {
          const fieldsName = Object.keys(updatedField)[0] as keyof ITaskFields;
          if (fieldsName === 'priority') setIsPriority(!updatedField[fieldsName as keyof ITaskFields]);
        })
    } else !!props.onUpdate && props.onUpdate({ ...props.singleTask, ...updatedField })
  }

  return (
    <div className={`single-task-container ${props.singleTask?.parentId ? 'subtask-item' : ''} ${isMobile ? 'single-task-container-mobile' : ''}`} style={{ width: "100%" }} data-testid={!!props.singleTask?.parentId ? 'sub-task' : `task-${props.singleTask.name || ""}`}>
      <TasksListSingleItem
        {...props}
        onUpdateTask={onUpdateTask}
        isPriority={isPriority}
        setIsPriority={setIsPriority}
        testid={'task-desktop'}
        className={`${props.className} ${props.shouldDisplayActionButtons ? 'single-task-desktop-view' : ''}`} />
      {props.shouldDisplayActionButtons && ![ETaskViewType.MY_WEEK, ETaskViewType.CALENDAR_PLACEMENT].includes(props.viewType!) &&
        <SwipeableSingleTaskItemMobile
          {...props}
          onUpdateTask={onUpdateTask}
          isPriority={isPriority}
          setIsPriority={setIsPriority} />
      }
    </div>
  )
}

export default SingleTaskItemContainer
