import { DropTargetMonitor } from "react-dnd";
import { calendarHourLineElementClassName, calendarHourLineTransparentElClassName } from "../../../../app/constants";
import { store } from "../../../../app/store";
import { getAllDomElementsByQuerySelector, getDomElementByQuerySelector } from "../../../../shared/utils/utils";
import { setCalendarDragAndDropSnap } from "../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store";
import { IDragItem } from "./CalendarEvent";
import { RefObject } from "react";

export const calcCalendarDragAndDropSnap = () => {
  const calendarHourLineElement = getDomElementByQuerySelector(`.${calendarHourLineTransparentElClassName}`);
  if (calendarHourLineElement) {
    const calendarHourLineElementRect = calendarHourLineElement.getBoundingClientRect();
    const calendarHourLineElementHeight = calendarHourLineElementRect.height;
    const calendarHourLineElementWidth = calendarHourLineElementRect.width;
    store.dispatch(setCalendarDragAndDropSnap({ snappedX: calendarHourLineElementWidth, snappedY: calendarHourLineElementHeight }));
  }
}

// get the closest hour line element to the start time of the dropped item and return the hour and minutes this line element presents
export const getDropTimeOnCalendar = (droppedItem: IDragItem, dayIndex: number): { hour: number, minutes: number } => {
  const snapYDroppedPosition = Math.round(droppedItem?.snapYDroppedPosition || 0);
  const offset = (store.getState().StagePlannerReducer.calendarDragAndDropSnap?.snappedY || 20) / 2;
  let hour = 0;
  let minutes = 0;
  let lineHourElTopPosition: number | null = null
  getAllDomElementsByQuerySelector(`.${calendarHourLineElementClassName}.dayIndx-${dayIndex}:not(.${calendarHourLineTransparentElClassName})`)
    ?.forEach(el => {
      lineHourElTopPosition = el.getBoundingClientRect().top;
      if (snapYDroppedPosition <= lineHourElTopPosition + offset && snapYDroppedPosition >= lineHourElTopPosition - offset) {
        hour = Number(el.getAttribute("data-hour"));
        minutes = Number(el.getAttribute("data-minutes"));
      }
    })

  return { hour, minutes };
}

export const autoScrollWhenDraggingNearTheTopOrBottom = (monitor: DropTargetMonitor<IDragItem, unknown>,scrollableContainerRef: RefObject<HTMLDivElement>) => {
  // Auto-scroll the scrollable container when dragging near the top/bottom
  const scrollThreshold = 40; // Distance from the top/bottom to trigger scrolling
  const scrollSpeed = 6; // Speed of the scrolling
  const clientOffset = monitor.getClientOffset();
  const scrollableContainer = scrollableContainerRef.current;
  if(!scrollableContainer) return;
  const boundingRect = scrollableContainer.getBoundingClientRect();

  if (clientOffset) {
      const topDistance = clientOffset.y - boundingRect.top;
      const bottomDistance = boundingRect.bottom - clientOffset.y;

      // Auto-scroll up if near the top
      if (topDistance < scrollThreshold) {
          scrollableContainer.scrollTop -= scrollSpeed;
      }

      // Auto-scroll down if near the bottom
      if (bottomDistance < scrollThreshold) {
          scrollableContainer.scrollTop += scrollSpeed;
      }
  }
}