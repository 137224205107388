import { XYCoord } from "react-dnd"
import { ICalendarDragAndDropSnap } from "../../../features/chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.interface"
import { CSSProperties } from "react"
import { IEventStyleForDestinationPreview } from "../../utils/utils"

interface IGetItemStyles {
  style: CSSProperties,
  snapY?: number
}

export function snapToGrid(x: number, y: number, snapOffsets:ICalendarDragAndDropSnap): [number, number] {
  const snappedX = Math.round(x / snapOffsets.snappedX) * snapOffsets.snappedX
  const snappedY = Math.round(y / snapOffsets.snappedY) * snapOffsets.snappedY
  return [snappedX, snappedY]
}

export const getPreviewTransformStyle = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  isSnapToGrid: boolean,
  snapOffsets: ICalendarDragAndDropSnap | null
): IGetItemStyles => {
  if (!initialOffset || !currentOffset) {
    return {
      style: { display: 'none' },
    }
  }

  let { x, y } = currentOffset

  if (isSnapToGrid && snapOffsets) {
    x -= initialOffset.x
    y -= initialOffset.y
    ;[x, y] = snapToGrid(x, y, snapOffsets)
    x += initialOffset.x
    y += initialOffset.y
  }

  const transform = `translate(${x}px, ${y}px)`
  return {
    style: {
      transform,
      WebkitTransform: transform
    },
    snapY: y
  }
}

export const getStyleForDraggingPreview = (DraggableItemRef: React.MutableRefObject<HTMLElement | null>, leftPosition: number | null): IEventStyleForDestinationPreview => {
  if (!DraggableItemRef.current) return {};
  const computedStyles = getComputedStyle(DraggableItemRef.current);
  return {
    height: computedStyles.height,
    width: computedStyles.width,
    backgroundColor: computedStyles.backgroundColor,
    border: computedStyles.border,
    borderRadius: computedStyles.borderRadius,
    boxShadow: computedStyles.boxShadow,
    zIndex: '9999',
    left: leftPosition || 0,
  }
}