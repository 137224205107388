import { Fragment, FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { IHumanStudentTurnSendInputPayload } from '../../../chat.interfaces';
import { setQueueMessageData, setSelectedOptionIndex } from '../../../chat.store';
import { chatSessionIdLocalStorageKey } from '../../../../../app/constants';
import { useLocalStorage } from '../../../../../shared/utils/useLocalStorage';
import PrepopulateOptionItem from './PrepopulateOptionItem';
import { EAPIStatus } from '../../../../../shared/api/models';
import './PrepopulateOptionsList.scss';

const PrepopulateOptionsList: FunctionComponent = () => {
  const { sessionId, prepopulatedOptions, botResponse, selectedOptionIndex } = useAppSelector(store => store.chatReducer);
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const dispatch = useAppDispatch();

  const onSelectOption = (index:number) => {   
    dispatch(setSelectedOptionIndex(index));
    const currentOption = prepopulatedOptions![index];
    const sessionIdReq = sessionId?.data?.sessionId || sessionIdLocalStorage;
    const payload: IHumanStudentTurnSendInputPayload = {
      sessionId: sessionIdReq,
      option: currentOption
    }
    dispatch(setQueueMessageData({ type: 'manual', botRequestJson: JSON.stringify(payload)}));
  }

  return (
    <div className={`options-container ${selectedOptionIndex===null && botResponse.status===EAPIStatus.PENDING && 'd-none'}`}>
      {prepopulatedOptions?.map((option, i) =>
        <Fragment key={i}>
          <PrepopulateOptionItem option={option} onSelectOption={onSelectOption} index={i}/>
        </Fragment>
      )}
    </div>
  )

}
export default PrepopulateOptionsList;