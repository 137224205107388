import React, { CSSProperties, Dispatch, FunctionComponent, MouseEvent, SetStateAction, useEffect, useRef, useState } from 'react'
import { ICalendarEvent, IDropResult } from './CalendarDay'
import { ETaskFormType, ETaskSource, ETaskStatus, IMessageDataTask } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { store, useAppDispatch, useAppSelector } from '../../../../app/store';
import { onPlaceUnscheduledTask } from '../../plan.utils';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { setShouldOpenWorkBlockDetails, setWorkBlockForEdit } from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';
import { areDatesEqual, EDragAndDropType, getExternalEventSourceDetails, getWorkBlockOrder, IEventStyleForDestinationPreview, isTaskAssignToThisWorkBlock } from '../../../../shared/utils/utils';
import { setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { useDrag, useDrop } from 'react-dnd';
import WorkBlockTasksList from './work-block-tasks-list/WorkBlockTasksList';
import { EPlanDayCardDisplayType } from '../../plan-day-card/PlanDayCard';
import recurrenceIcon from '../../../../assets/images/single-task/task_recurrence_icon.svg';
import SassVariables from "../../../../styles/style.module.scss";
import { TransparentDefaultPreview } from '../../../../shared/components/dragged-destination-preview/DraggedDestinationPreview';
import { getStyleForDraggingPreview } from '../../../../shared/components/dragged-destination-preview/DragAndDrop.utils';

interface ICalendarEventProps {
  event: ICalendarEvent;
  isCalenderDayClickable: boolean;
  shouldShowHourText: boolean;
  calendarDayContainerWidth: number;
  playViewType: EPlanDayCardDisplayType;
  divideEventByGroups: (events: ICalendarEvent[]) => void;
  eventsFlatArray: ICalendarEvent[];
  dayIndex: number;
  convertSingleItemToCalendarEvent(el: IMessageDataTask): ICalendarEvent;
  updateDragEventApiRequest: (dragItem: IDragItem, updatedWorkTime: Date | null, relatedWorkBlockId?: string, relatedWorkBlockOrder?: number, relatedWorkBlockInstance?: Date | null) => void
  setDroppingProcessCounter: Dispatch<SetStateAction<number>>
}

export interface IDragItem {
  event: ICalendarEvent;
  eventStyle: IEventStyleForDestinationPreview;
  isComeFromWorkBlock?: boolean;
  snapYDroppedPosition?: number;
  overDropZone?: 'workBlock' | 'calendar';
}

export interface IUseDragCollectedProps {
  opacity?: number;
  cursor?: string;
  transition?: string;
}

export interface IWorkBlockDropResult extends IDropResult {
  droppedWorkBlockId: string;
}

const CalendarEvent: FunctionComponent<ICalendarEventProps> = ({
  event,
  dayIndex,
  divideEventByGroups,
  eventsFlatArray,
  isCalenderDayClickable,
  shouldShowHourText,
  calendarDayContainerWidth,
  playViewType,
  convertSingleItemToCalendarEvent,
  updateDragEventApiRequest,
  setDroppingProcessCounter
}) => {
  const { allTasks } = useAppSelector(store => store.StageTasksReducer);
  const { plannerMode, currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const dispatch = useAppDispatch();
  const defaultLeftPosition = 40;
  const reductionFromContainerWidth = shouldShowHourText ? 40 : 0;
  const marginBetweenEvents = 2;
  const eventRef = useRef<HTMLDivElement | null>(null);
  const leftPosition = calcEventLeft();
  const [draggingPreviewStyle, setDraggingPreviewStyle] = useState(getStyleForDraggingPreview(eventRef,leftPosition));
  
  useEffect(() => {
    setDraggingPreviewStyle({...getStyleForDraggingPreview(eventRef,leftPosition)});
  },[eventRef, leftPosition])

  // Dragging is permitted only if:
  // 1. The event is not recurring, and it is not the current task placement.
  // 2. The event is not external.
  const canDrag = () => {
    const isNotRecurring = !event.isRecurring;
    const isNotCurrentTaskPlacement = currentTaskPlacement?.id !== event.id;
    const isNotExternalEvent = !event.isEvent || event.source === ETaskSource.Internal
    
    return(
      isNotRecurring && isNotCurrentTaskPlacement && isNotExternalEvent
    )
  }

  const [{ opacity, cursor }, dragRef, dragPreview] = useDrag<IDragItem, unknown, IUseDragCollectedProps>({
    type: EDragAndDropType.CALENDAR_EVENT,
    item: { event, eventStyle: draggingPreviewStyle }, // Data about the event being dragged
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      cursor: monitor.isDragging() ? 'move' : 'pointer',
    }),
    canDrag: () => canDrag(),
    end(draggedItem, monitor) {
      const dropResult: IDropResult | null = monitor.getDropResult();
      // if dropped in different day, remove the event from the current day (else, the event is already updated in the current day inside the drop function)
      if (monitor.didDrop() && dropResult?.dayIndex !== dayIndex) {
        divideEventByGroups(eventsFlatArray.filter(e => e.id !== draggedItem.event.id));
      }
    },
  });

  const updateLocalDroppedWorkBlockEvent = (task: IMessageDataTask, taskWorkBlockOrder: number, taskWorkBlockInstance: Date | null) => {
      const updatedTask = { 
        ...task,
        workBlockId: event?.id,
        workBlockInstance: taskWorkBlockInstance,
        workBlockOrder: taskWorkBlockOrder,
        workTime: null,
        workTimeRecurrenceType: null,
        workTimeReminder: null,
      }
        const tempEventsArr = [...eventsFlatArray];
        const workBlockEvent = tempEventsArr.find(e => e.id === event.id);
        if (workBlockEvent) {
          workBlockEvent.relatedTasks.push(updatedTask);
          divideEventByGroups([...tempEventsArr].filter(e => e.id !== task.id));
        }
  }

  const dropInToWorkBlock = (dragItem: IDragItem): IWorkBlockDropResult => {
    setDroppingProcessCounter(prev => prev + 1);
    const task = allTasks.find(t => t.id === dragItem.event.id);
    const taskWorkBlockInstance = event.isRecurring ? event.start : null;
    // if the task is not already assigned to this workBlock, update the task and the workBlock
    if (!!task && !isTaskAssignToThisWorkBlock(event.id, taskWorkBlockInstance || "", task?.workBlockId, task?.workBlockInstance)) {
      const taskWorkBlockOrder = getWorkBlockOrder(event.relatedTasks[event.relatedTasks.length - 1]?.workBlockOrder);
      updateLocalDroppedWorkBlockEvent(task, taskWorkBlockOrder, taskWorkBlockInstance);
      updateDragEventApiRequest(dragItem, null, event.id, taskWorkBlockOrder, taskWorkBlockInstance);
    }
    return {dayIndex, droppedWorkBlockId: event.id}
  }

  // drop into work block
  // dropRef is used only for work blocks to make them droppable
  const [{ isDraggableItemOverClassName }, dropRef] = useDrop({
    accept: EDragAndDropType.CALENDAR_EVENT,
    drop: (dragItem: IDragItem) => dropInToWorkBlock(dragItem),
    canDrop: (dragItem: IDragItem) => !dragItem.event.isEvent,
    collect: (monitor) => ({
      isDraggableItemOverClassName: monitor.isOver() && !monitor.getItem().event.isEvent && !monitor.getItem().event.isWorkBlock ? 'draggable-item-is-over' : '',
    }),
    hover(item, ) {
      item.overDropZone = 'workBlock';
    },
  });

  const onPlaceTaskInsideWorkBlock = (workBlockEvent: ICalendarEvent) => {
    onPlaceUnscheduledTask(workBlockEvent.start, workBlockEvent.id, workBlockEvent.title, workBlockEvent.isRecurring, workBlockEvent.relatedTasks.length ? getWorkBlockOrder(workBlockEvent.relatedTasks[workBlockEvent.relatedTasks.length - 1]?.workBlockOrder) : 0);
  }

  const onClickWorkBlockEvent = (workBlockEvent: ICalendarEvent) => {
    if (plannerMode === EPlannerMode.TIMEPICKER) {
      onPlaceTaskInsideWorkBlock(workBlockEvent);
      return;
    }
    const workBlock = store.getState().StageTasksReducer.allWorkBlocks.find(w => w.id === workBlockEvent.id && areDatesEqual(w.workTime, workBlockEvent.start));
    if (workBlock) {
      dispatch(setWorkBlockForEdit(workBlock));
      dispatch(setShouldOpenWorkBlockDetails(true));
    }
  }

  const handleEventClick = (clickEvent: MouseEvent<HTMLElement, any>, event: ICalendarEvent, workBlockTask?: IMessageDataTask) => {
    clickEvent.stopPropagation();
    if (!isCalenderDayClickable) return;
    if (((plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER) && currentTaskPlacement)) {
      if (event.isWorkBlock) {
        onPlaceTaskInsideWorkBlock(event);
        return;
      }
      onPlaceUnscheduledTask(event.start);
      return;
    };
    if (workBlockTask && plannerMode !== EPlannerMode.TIMEPICKER) {
      dispatch(setSelectedMainTaskForEditing(workBlockTask));
      dispatch(setShouldOpenAddEditTaskFrom(ETaskFormType.Task));
      return
    }
    if (!event.isWorkBlock && plannerMode === EPlannerMode.TIMEPICKER) {
      onPlaceUnscheduledTask(event.start);
      return;
    }
    if (event.isWorkBlock) onClickWorkBlockEvent(event);
    else {
      const task = store.getState().StageTasksReducer.allTasks.find(t => event.parentId ? t.id === event.parentId : t.id === event.id);
      if (task) {
        dispatch(setSelectedMainTaskForEditing(task));
        dispatch(setShouldOpenAddEditTaskFrom(event.isEvent ? ETaskFormType.Event : ETaskFormType.Task));
      }
    }
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
  }

  function calcEventLeft(){
    const leftPosition = shouldShowHourText ? defaultLeftPosition : 0;
    if (event.columnOffset === 0) return event.columnOffset! * (calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns! + leftPosition;
    return event.columnOffset! * (calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns! + leftPosition;
  }

  const getEventStyle = (): CSSProperties => {
    return ({
      position: 'absolute',
      top: event.top,
      left: leftPosition,
      height: event.height,
      width: (((calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns!) - marginBetweenEvents) + 'px',
      transition: 'none',
      opacity,
      cursor
    })
  }

  const getEventInnerContainerStyle = (): CSSProperties => { 
    return ({
      backgroundColor: event.backgroundColor, 
      border: `${event.backgroundColor === "#FFF" ? "1px solid " + SassVariables.MaxDarkColor : 'none'}`
    })
  }

  return (
    <>
    <div
      key={event.id}
      ref={(node) => {
        dragRef(node);
        if(event.isWorkBlock) dropRef(node);
      }}
      className={`calendar-event calendar-event--${event.durationType} ${event?.isWorkBlock ? 'calendar-event--work-block' : ''}`}
      onClick={(e) => { e.stopPropagation(); handleEventClick(e, event) }}
      style={getEventStyle()}
    >
      <div ref={eventRef} className={`calendar-event-inner-container calendar-event-inner-container--${event.durationType} ${isDraggableItemOverClassName}`} style={getEventInnerContainerStyle()}>
        <div className="event-text-container">
          <div className='title-container'>
            <h1 className={`calendar-event-title calendar-event-title--${playViewType === EPlanDayCardDisplayType.MY_DAY ? "my-day" : "my-week"} ${event.status === ETaskStatus.DONE ? event.titleColor !== "white" ? 'completed-task completed-task--grey' : 'completed-task' : ''}`} style={{ color: event.titleColor }}>{event.title}</h1>
          </div>
          {!event?.isWorkBlock && event.source !== ETaskSource.Internal && <span className='calendar-event-addition-text' style={{ filter: event.backgroundColor !== 'transparent' && event.backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }}>{getExternalEventSourceDetails(event.source)}</span>}
          {event?.isWorkBlock && 
            <WorkBlockTasksList 
              workBlock={event} 
              onClickWorkBlockTask={handleEventClick} 
              planViewType={playViewType} 
              relatedTasks={event.relatedTasks}
              eventsFlatArray={eventsFlatArray}
              dayIndex={dayIndex}
              divideEventByGroups={divideEventByGroups} 
              convertSingleItemToCalendarEvent={convertSingleItemToCalendarEvent}
              workBlockLeftPosition={leftPosition}
            />
          }
        </div>
        {!event?.isWorkBlock &&
          <div className='calendar-event-addition-info-container'>
            <img className={`${!event.isRecurring ? ' visibility-hidden' : ''}`} src={recurrenceIcon} alt="recurrence-icon" style={{ filter: event.backgroundColor !== 'transparent' && event.backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }} />
          </div>
        }
      </div>
    </div>
    {/* overriding the default preview with transparent preview */}
    <TransparentDefaultPreview dragPreview={dragPreview} />
   </>
  )
}

export default CalendarEvent