import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ECalendarSyncType, EExternalCalendarProvider, IUser } from '../../../../../../app/auth/auth.interfaces';
import { API_ROUTES, userInfoLocalStorageKey } from '../../../../../../app/constants';
import GoogleLogo from '../../../../../../assets/images//Google-logo.png';
import MicrosoftOutlookLogo from '../../../../../../assets/images//Microsoft-Outlook-logo.png';
import ImportAndExportIcon from '../../../../../../assets/images//import-and-export-icon.png';
import ExportIcon from '../../../../../../assets/images//export-icon.png';
import ImportIcon from '../../../../../../assets/images//import-icon.png';
import { getItemFromLocalStorage } from '../../../../../utils/localStorage.utils';
import backIcon from "../../../../../../assets/images/back-icon.svg"
import SyncButton from './SyncButton'

export const baseUrl = window.location.origin;

const calendarLoginRoutes = {
    [EExternalCalendarProvider.GOOGLE]: API_ROUTES.AUTH.GOOGLE_CALENDAR_LOGIN,
    [EExternalCalendarProvider.OUTLOOK]: API_ROUTES.AUTH.OUTLOOK_CALENDAR_LOGIN
};

export const CalendarSyncPopover = () => {
    const { t } = useTranslation();
    const [selectedCalendarType, setSelectedCalendarType] = useState<string | undefined | null>(null);

    const authRedirect = (syncType: ECalendarSyncType) => {
        const calendarLoginRoute = selectedCalendarType ? calendarLoginRoutes[selectedCalendarType as EExternalCalendarProvider] : null;
        // Assign userId to requestId
        // Pass userId to the redirect function to identify the authenticated user
        window.location.href = `${process.env.REACT_APP_BASE_URL_CSHARP}/${API_ROUTES.AUTH.REDIRECT}/${selectedCalendarType}?redirectUri=${process.env.REACT_APP_BASE_URL_CSHARP}/${calendarLoginRoute}&isCalendarLogin=true&syncType=${syncType}&requestId=${getItemFromLocalStorage<IUser>(userInfoLocalStorageKey)?.id}&state=${baseUrl}/app/chat?settingsMenu=open`;
    };

    return (
        <div className="calendar-sync-popover-container">
            {
            !!selectedCalendarType ?
            <div>
                <section className="title-and-back-section">
                    <button id="close-calendar-sync-overlay" type="button" className='calendar-sync-overlay-header-back' onClick={() => setSelectedCalendarType(null)}><img src={backIcon} alt="back" /></button>
                    <h4 className='calendar-sync-overlay-header-text static-string'>
                        {t('settingsPersonalInfoCalendarSyncType')}
                    </h4>
                </section>               
                    
                <div className="calendar-sync-type-buttons">
                    <SyncButton 
                        syncType={ECalendarSyncType.FULL}
                        icon={ImportAndExportIcon}
                        title='settingsPersonalInfoCalendarImportAndExportTitle'
                        subtitle='settingsPersonalInfoCalendarImportAndExportSubTitle'
                        imageAltText='importAndExportImageAltText'
                        onClick={authRedirect}
                    />
                    <SyncButton 
                        syncType={ECalendarSyncType.EXPORT}
                        icon={ExportIcon}
                        title='settingsPersonalInfoCalendarExportTitle'
                        subtitle='settingsPersonalInfoCalendarExportSubTitle'
                        imageAltText='exportImageAltText'
                        onClick={authRedirect}
                    />
                    <SyncButton 
                        syncType={ECalendarSyncType.IMPORT}
                        icon={ImportIcon}
                        title='settingsPersonalInfoCalendarImportTitle'
                        subtitle='settingsPersonalInfoCalendarImportSubTitle'
                        imageAltText='importImageAltText'
                        onClick={authRedirect}
                    />
                </div>
            </div>
                :
                <>
                <h4 className="calendar-sync-popover-title">{t('settingsPersonalInfoCalendarChooseService')}</h4>
                <div className="calendar-sync-popover-buttons">
                    <button className="calendar-sync-popover-google-button"
                        onClick={() => setSelectedCalendarType(EExternalCalendarProvider.GOOGLE)}>
                        <img src={GoogleLogo} alt={t('loginGoogleLogoImageAltText')} />
                        {t('syncWithGoogle')}
                    </button>
                    <button className="calendar-sync-popover-microsoft-outlook-button"
                        onClick={() => setSelectedCalendarType(EExternalCalendarProvider.OUTLOOK)}>
                        <img src={MicrosoftOutlookLogo} alt={t('loginMicrosoftOutlookLogoImageAltText')} />
                        {t('syncWithMicrosoftOutlook')}
                    </button>
                </div></>
            }
        </div>
    );
};
