import { usePreview } from 'react-dnd-multi-backend';
import { IDragItem } from '../../../features/plan/calendar/calendar-day/CalendarEvent';
import { ConnectableElement, DragPreviewOptions } from 'react-dnd';
import { ReactElement, useEffect, useRef } from 'react';
import { useAppSelector } from '../../../app/store';
import { EDragAndDropType } from '../../utils/utils';
import { getPreviewTransformStyle } from './DragAndDrop.utils';

export const DraggedDestinationPreview = () => {
  const ref = useRef(null);
  const preview = usePreview();
  const { calendarDragAndDropSnap } = useAppSelector(store => store.StagePlannerReducer);

  if (!preview.display) {
    return null;
  }

  const { item, itemType, style, monitor } = preview;
  const previewStyle = (item as IDragItem)?.eventStyle || {};

  const initialOffset = () => {
    if(!monitor.getInitialSourceClientOffset()) return null;
    return {
      y: monitor.getInitialSourceClientOffset()!.y,
      x: monitor.getInitialSourceClientOffset()!.x - (previewStyle?.left || 0)
    }
  }

  const shouldSnap: boolean = itemType === EDragAndDropType.CALENDAR_EVENT;

  const previewTransformAndPosition = getPreviewTransformStyle(initialOffset(),monitor.getSourceClientOffset(),shouldSnap,calendarDragAndDropSnap);
  
  const localStyle = {
    ...style, 
    ...previewStyle,
    ...previewTransformAndPosition.style,
    left:0,
    width: calendarDragAndDropSnap?.snappedX || previewStyle.width,
  };
  
  (item as IDragItem).snapYDroppedPosition = previewTransformAndPosition.snapY;

  return (
    <div ref={ref} style={localStyle} />
  );
}


// The custom preview using usePreview() (inside DraggedDestinationPreview component) is displayed together with the default preview in desktop.
// This component return a transparent element for  on desktop so the custom preview is displayed alone
export const TransparentDefaultPreview = ({ dragPreview }: ITransparentDefaultPreview) => {
  const transparentRef = useRef(null);

  useEffect(() => {
    if (transparentRef.current) {
      dragPreview(transparentRef.current);
    }
  }, [dragPreview]);

  return (
    <div
      ref={transparentRef}
      style={{
        position: 'fixed',
        top: -9999,
        left: -9999,
        width: 0,
        height: 0,
        opacity: 0,
      }}
    />
  )
}

interface ITransparentDefaultPreview {
  dragPreview: (elementOrNode: ConnectableElement, options?: DragPreviewOptions | undefined) => ReactElement | null
}